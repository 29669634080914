/* Mobile styles only */
@media (max-width: 768px) {
  .blog-title {
    font-size: clamp(24px, 5vw, 30px) !important;
  }

  .blog-card {
    width: 100% !important;
    max-width: 780px !important;
  }

  .blog-card-image,
  .blog-card-image.small,
  .blog-card-image.no-border,
  .blog-card-image.no-border-4,
  .blog-card-image.no-border-5 {
    width: 100% !important;
    height: 300px !important;
  }

  .blog-card-title {
    font-size: clamp(16px, 4vw, 22px) !important;
    padding: 0 15px !important;
  }

  .blog-card-date {
    font-size: clamp(12px, 2vw, 14px) !important;
  }

  .blog-row {
    flex-direction: column !important;
    align-items: center !important;
    gap: 20px !important;
  }

  .read-more-button {
    font-size: clamp(14px, 2vw, 16px) !important;
    padding: 6px 20px !important;
  }

  .cardDateContainer,
  .cardDateContainerOpposite {
    padding: 0 15px !important;
  }
} 