.content-container {
    gap: 2rem;
    padding-bottom: 4rem;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  .card1 {
    height: 520px;
  }

  .abstract-right, .abstract-left {
    position: absolute;
    width: auto;
    max-height: auto;
  }
  
  .abstract-right {
    left: 0;
    top: 0;
  }
  
  .abstract-left {
    right: 0;
    top: 0;
  }
  
  .why-bfag-image-container {
    margin: 3rem 0;
  }
  
  .image-border {
    border: 3px solid #072f5f;
    border-radius: 15px;
  }
  
  .why-bfag-text-container {
    margin-left: -100px; /* Adjusted for more left alignment */
    text-align: center;
  }
  
  .why-bfag-title {
    font-size: 30px;
    color: #072f5f;
    font-weight: bold;
    text-align: center;
  }
  
  .why-bfag-paragraph {
    text-align: justify;
    padding-left: 5rem;
    padding-right: 4rem;
  }
  
  .cards-section {
    background-color: #072f5f;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  .design-left, .design-right {
    position: absolute;
  }
  
  .design-left {
    left: -3%;
    width: 45%;
    top: 0;
  }
  
  .design-right {
    right: -3%;
    width: 50%;
    bottom: 0;
  }
  
  .card-container {
    margin-bottom: 1rem;
    /* height:auto; */
  }
  
  .card-custom {
    border-radius: 15px;
    /* height: auto; */
    background-color: white;
    margin-bottom: '1rem';

  }
  
  .card-body-one {
    padding-top: 1rem !important;
    font-size: 12px;
  }
  
  .card-title {
    display: inline-block;
    border-bottom: 3px solid #072f5f;
    color: #072f5f;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  
  .card-text {
    font-size: 12px;
    text-align: justify;
    color: black;
  }
  
  .res-img {
    width: 50%;
  }
  
  @media screen and (max-width: 768px) {
    .why-bfag-text-container {
      margin-left: -20px; 
      padding: 0 20px;
    }
  
    .why-bfag-paragraph {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  
    .card-container {
      flex-basis: 100%;
      max-width: 100%;
      padding: 10px; /* Equal padding from all sides */
      box-sizing: border-box; /* Ensure padding is inside the card dimensions */
    }
  
    .card-custom {
      margin: 20px 20px; /* Top and bottom margin for spacing between cards */
    }

     
  .card-container:nth-child(4) {
    margin-left: 4%;
    
  }
  .card-container:nth-child(1) {
margin-right: 5%;
  }
  }
  
  