#myDiv {
    position: relative;
    border: none;
    padding: 10px;
  }
  
  #myDiv:before {
    content: '';
    position: absolute;
    top: 3px;
    left: -5px;
    border-top: 5px solid #21ac51;
    border-left: 5px solid #21ac51;
    width: 10%;
    height: 50%;
  }
  
  #myDiv:after {
    content: '';
    position: absolute;
    bottom: 14px;
    right: -5px;
    border-bottom: 5px solid #21ac51;
    border-right: 5px solid #21ac51;
    width: 10%;
    height: 50%;
  }
  
  /* #myDivi:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top: 5px solid #3764EB;
    border-left: 5px solid #3764EB;
    width: 10%;
    height: 50%;
  }
  
  #myDivi:after {
    content: '';
    position: absolute;
    bottom: 10px;
    right: 0;
    border-bottom: 5px solid #3764EB;
    border-right: 5px solid #3764EB;
    width: 10%;
    height: 50%;
  } */
  
  .shade{
   background: linear-gradient(#418052  0%
   rgba(50, 217, 81, 0) 100%);
  }
  
  
  ul{
    list-style-type:circle !important;
  }
  
  ul li {
    display: flex;
    align-items: start;
    padding-bottom: 5px;
  }
  
  li {
    font-size: 16px;
    color: #353535;
  }
  
  p {
    font-size: 16px;
  }
  
  .card{
    height: 450px;
  }
  
  @media(max-width:574px){
    .mTop{
      margin-top: 80px;
    }
  
    .card{height: 490px;}
  
    .missionh3{
      font-size: 18px !important;
    }
    .imgmission{
      width: 100%;
    }
  }
  @media(min-width:576px){
    .mTop{
      margin-top: 80px;
    }
  }
  
  @media(min-width:976px){
    .mTop{
      margin-top: 140px;
    }
  }
  
  
  