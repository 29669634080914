/* Navbar.css */

/* Show the dropdown menu on hover */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

/* Ensure the dropdown menu has a consistent display style */
.dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

/* Additional styling to match your theme */
.nav-link {
  cursor: pointer;
}

.nav-item.dropdown:hover .nav-link {
  color: #0056b3; /* Optional: Change color on hover */
}




a {
    border-bottom: none !important;
    cursor: pointer;
  }
  
  ul li a {
    font-weight: 500 !important;
  }
  
  .india .navigation .navbar .navbar-nav .nav-item .sub-menu > li:hover > a {
    background-color: #fff;
    color: #072f5f !important;
    padding-left: 20px !important;
  }
  
  .indiaToggler li.nav-item.active a.nav-link {
    color: #072f5f !important;
  }
  
  li.indiaItem.active a.indiaLink {
    color: #072f5f !important;
  }
  
  a.indiaLink.active,
  a.active {
    color: #072f5f !important;
  }
  
  /* Dubai */
  .dubai .navigation .navbar .navbar-nav .nav-item .sub-menu > li:hover > a {
    background-color: #fff;
    color: #418052 !important;
    padding-left: 20px !important;
  }
  
  .dubaiToggler li.nav-item.active a.nav-link {
    color: #418052 !important;
  }
  
  /* li.dubaiItem.active a.dubaiLink{
    color: #418052!important;
  }
  
  a.dubaiLink.active, a.active{
    color: #418052!important;
  } */
  
  /* Austin */
  .austin .navigation .navbar .navbar-nav .nav-item .sub-menu > li:hover > a {
    background-color: #fff;
    color: #ff9d2e !important;
    padding-left: 20px !important;
  }
  
  .austinToggler li.nav-item.active a.nav-link {
    color: #ff9d2e !important;
  }
  
  li.austinItem.active a.austinLink {
    color: #ff9d2e !important;
  }
  
  a.austinLink.active,
  a.active {
    color: #ff9d2e !important;
  }
  