/* carousel.css */
.carousel .slide {
    background: none;
}
.carousel .control-dots .dot {
    background: #000;
}

.testimonial-container {
    position: relative;
    width: 100%;
    padding: 2rem;
    background-color: white; /* Ensures the background color of the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.background-shape {
    position: absolute;
    width: 20%;
    transform: rotate(180deg);
    overflow: hidden;
    z-index: -1;
}

.background-shape.left {
    top: 0;
    left: -5%;
}

.background-shape.right {
    bottom: -10%;
    right: -5%;
}
