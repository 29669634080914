/* Desktop styles (default) */
.blog-card {
  width: 780px !important;
}

.blog-card-image {
  width: 780px !important;
}

.blog-card-image.small {
  width: 400px !important;
}

.blog-card-image.no-border {
  width: 400px !important;
}

.blog-card-image.no-border-4 {
  width: 380px !important;
}

.blog-card-image.no-border-5 {
  width: 380px !important;
}

/* Mobile styles */
@media (max-width: 768px) {
  .blog-title {
    font-size: clamp(24px, 5vw, 30px);
  }

  .blog-card {
    width: 100% !important;
    max-width: 780px !important;
  }

  .blog-card-image {
    width: 100% !important;
    height: 300px !important;
  }

  .blog-card-image.small,
  .blog-card-image.no-border,
  .blog-card-image.no-border-4,
  .blog-card-image.no-border-5 {
    width: 100% !important;
    height: 300px !important;
  }

  .blog-card-title {
    font-size: clamp(16px, 4vw, 22px);
  }

  .blog-card-date {
    font-size: clamp(12px, 2vw, 14px);
  }

  .blog-row {
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }

  .read-more-button {
    font-size: clamp(14px, 2vw, 16px);
  }
} 