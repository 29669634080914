/* Custom styling for form inputs with bottom shadow */
.shadow-form .form-control,
.shadow-form .input-group-text {
  border: none; /* Remove borders */
  border-radius: 0; /* No rounded corners */
  box-shadow: none; /* Remove default Bootstrap shadow */
  border-bottom: 2px solid transparent; /* Transparent border for space */
  padding: 0.5rem 0.75rem; 
  background-color: #ffffff;
}

.shadow-form .form-control:focus,
.shadow-form .input-group-text:focus {
  border-bottom: 2px solid #007bff; /* Bottom border on focus */
  outline: none; /* Remove outline */
  box-shadow: none; /* Remove default Bootstrap focus shadow */
}

.shadow-form .shadow-input .form-control,
.shadow-form .shadow-input .input-group-text {
  border-bottom: 1px solid #ccc; /* Default bottom border */
}

.shadow-form .shadow-input .form-control:hover,
.shadow-form .shadow-input .input-group-text:hover {
  border-bottom: 1px solid #007bff; /* Bottom border on hover */
}

/* Add padding and background for better visual separation */
.shadow-form .form-control {
  padding: 0.75rem;
  /* background-color: #f9f9f9; */
}

/* Styling the form container */
.shadow-form {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for container */
}

/* Additional styling for the captcha refresh button */
.sync {
  cursor: pointer;
  color: #007bff;
}

.sync:hover {
  color: #0056b3;
}

/* Styling for invalid fields */
.is-invalid {
  border-bottom-color: #dc3545 !important;
}

/* Captcha image/container styling */
#captcha {
  /* font-size: 1.5rem; */
  background-color: #ffffff;
  padding: 0.5rem;
  border-radius: 5px;
}
/* Center the video/image only in mobile view */
@media (max-width: 768px) {
  .img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .unique-img-fluid {
    max-width: 100%; /* Ensure responsiveness */
    height: auto; /* Maintain aspect ratio */
  }

  .unique-rounded {
    border-radius: 10px; /* Apply rounded corners */
  }
}

